<template>
  <div class="con-wrap">
    <CarrotTitle title="나의 자산관리">
      - 나의 자산보유 목록과 나의 대여 현황을 확인할 수 있는 곳입니다.<br />
      - 개인부주의로 인한 분실 및 파손시 자기부담금 : 자산구매일로부터 1년 이하
      50%, 3년 이하 30%, 3년 이상 20% (구매액 기준)
    </CarrotTitle>
    <div class="board">
      <div class="mt-40">
        <div>
          <h3 class="block-title mb-20">나의 자산보유 목록</h3>
          <div class="mb-20 dp-table w-100per">
            <button
              v-if="assets.is_report == 'Y'"
              class="btn-default ml-10 float-right"
              @click="assets.doReport()"
            >
              자산 신고
            </button>
            <button
              class="btn-default ml-10 float-right"
              @click="assets.doReceipt()"
            >
              수령 확인
            </button>
          </div>
          <table class="table-col">
            <!-- <colgroup>
              <col width="30" />
              <col width="50" />
              <col width="150" />
              <col width="120" />
              <col width="140" />
              <col width="160" />
              <col width="150" />
              <col width="120" />
              <col width="150" />
              <col v-if="assets.is_report == 'Y'" width="150" />
            </colgroup> -->
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    v-model="assets.checkAll"
                    @change="assets.doCheckAll"
                  />
                </th>
                <th>No</th>
                <th>자산번호</th>
                <th>소분류</th>
                <th>모델명</th>
                <th>시리얼</th>
                <th>지급일</th>
                <th>수령자</th>
                <th>수령확인</th>
                <th v-if="assets.is_report == 'Y'">자산신고</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(irow, i) in assets.list" :key="i">
                <td><input type="checkbox" v-model="irow.checked" /></td>
                <td class="pointer" @click="assets.doCheck(i)">
                  {{ irow.num }}
                </td>
                <td class="pointer" @click="assets.doCheck(i)">
                  {{ irow.code }}
                </td>
                <td class="pointer" @click="assets.doCheck(i)">
                  {{ irow.cat2 }}
                </td>
                <td class="pointer" @click="assets.doCheck(i)">
                  {{ irow.model }}
                </td>
                <td class="pointer" @click="assets.doCheck(i)">
                  {{ irow.serialno }}
                </td>
                <td class="pointer" @click="assets.doCheck(i)">
                  {{ irow.gifted_date }}
                </td>
                <td class="pointer" @click="assets.doCheck(i)">
                  {{ irow.ename }}({{ irow.kname }})
                </td>
                <td class="pointer" @click="assets.doCheck(i)">
                  {{ irow.receipt_date }}
                </td>
                <td
                  v-if="assets.is_report == 'Y'"
                  class="pointer"
                  @click="assets.doCheck(i)"
                >
                  {{ irow.report_date }}
                </td>
              </tr>
              <tr v-if="assets.total == 0 && assets.is_report == 'Y'">
                <td colspan="10">보유 자산이 없습니다.</td>
              </tr>
              <tr v-if="assets.total == 0 && assets.is_report == 'N'">
                <td colspan="9">보유 자산이 없습니다.</td>
              </tr>
            </tbody>
          </table>
          <CarrotPaging
            :total="assets.total"
            :list_per_page="assets.rows"
            v-model="assets.page"
            @change="assets.doSearch"
          ></CarrotPaging>

          <h3 class="block-title mt-40 mb-20">나의 대여 현황</h3>
          <table class="table-col">
            <!-- <colgroup>
              <col width="80" />
              <col width="400" />
              <col width="300" />
              <col width="160" />
              <col width="280" />
            </colgroup> -->
            <thead>
              <tr>
                <th>No</th>
                <th>자산번호</th>
                <th>소분류</th>
                <th>상태</th>
                <th>대여기간</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(irow, i) in rental.list" :key="i">
                <td>{{ irow.num }}</td>
                <td>{{ irow.code }}</td>
                <td>{{ irow.cat2 }}</td>
                <td>
                  <span :class="{ 'color-1': irow.state == '연체 중' }">{{
                    irow.state
                  }}</span>
                </td>
                <td>{{ irow.sdate }} ~ {{ irow.edate }}</td>
              </tr>
              <tr v-if="rental.total == 0">
                <td colspan="5">보유 자산이 없습니다.</td>
              </tr>
            </tbody>
          </table>
          <!-- 페이징 20개 -->
          <CarrotPaging
            :total="rental.total"
            :list_per_page="rental.rows"
            v-model="rental.page"
            @change="rental.doSearch"
          ></CarrotPaging>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from "vue";
import axios from "@/plugins/axios.js";
import Swal from "sweetalert2";
import { useToast } from "vue-toastification";

export default {
  layout: "myPIMS",

  components: {},
  setup() {
    const toast = useToast();
    const assets = reactive({
      page: 1,
      rows: 20,
      checkAll: false,
      list: [],
      total: 0,
      is_report: "N",
      doSearch: () => {
        assets.getList();
      },
      getList: () => {
        let params = {
          page: assets.page,
        };

        axios
          .get("/rest/mypims/getMyAssetsRelPopupList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              assets.list = res.data.list;
              assets.total = res.data.total;
            } else {
              console.info(res.data.err_msg);
            }
          });
      },
      doCheckAll: () => {
        let val = false;
        if (assets.checkAll == true) {
          val = true;
        }
        for (let i = 0; i < assets.list.length; i++) {
          assets.list[i].checked = val;
        }
      },
      doCheck: (i) => {
        if (assets.list[i].checked == false) assets.list[i].checked = true;
        else assets.list[i].checked = false;
      },
      //결재선 관련
      doReport: () => {
        var check_cnt = 0;
        var checkList = [];
        var check_idx = 0;
        for (let i = 0; i < assets.list.length; i++) {
          if (assets.list[i].checked) {
            check_cnt = check_cnt + 1;
            checkList[check_idx] = assets.list[i].idx;
            check_idx++;
          }
        }
        if (check_cnt == 0) {
          toast.error("자산을 선택해 주세요");
          return;
        }

        Swal.fire({
          title: "자산신고",
          text: "자산신고 등록하시겠습니까?",
          showCancelButton: true,
        }).then((res) => {
          if (res.isConfirmed == true) {
            let params = {
              checkList: checkList,
            };
            axios.post("/rest/mypims/reportAsset", params).then((res) => {
              if (res.data.err == 0) {
                toast.success("등록되었습니다.");
                assets.getList();
                assets.checkAll = false;
              } else {
                if (res.data.err_msg) toast.error(res.data.err_msg);
                assets.getList();
              }
            });
          }
        });
      },
      doReceipt: () => {
        var check_cnt = 0;
        var checkList = [];
        for (let i = 0; i < assets.list.length; i++) {
          if (assets.list[i].checked) {
            check_cnt = check_cnt + 1;
            checkList[i] = assets.list[i].idx;
          }
        }
        if (check_cnt == 0) {
          toast.error("자산을 선택해 주세요");
          return;
        }

        Swal.fire({
          title: "수령완료",
          text: "수령완료 처리하시겠습니까?",
          showCancelButton: true,
        }).then((res) => {
          if (res.isConfirmed == true) {
            let params = {
              checkList: checkList,
            };
            axios.post("/rest/mypims/receiptAsset", params).then((res) => {
              if (res.data.err == 0) {
                toast.success("등록되었습니다.");
                assets.getList();
                assets.checkAll = false;
              } else {
                if (res.data.err_msg) toast.error(res.data.err_msg);
              }
            });
          }
        });
      },
      checkAssetsReport: () => {
        axios
          .get("/rest/personal/checkAssetsReport", { params: {} })
          .then((res) => {
            if (res.data.err == 0) {
              assets.is_report = res.data.is_report;
            }
          });
      },
    });

    const rental = reactive({
      page: 1,
      rows: 20,
      list: [],
      total: 0,

      doSearch: () => {
        rental.page = 1;
        rental.getList();
      },
      getList: () => {
        let params = {
          page: rental.page,
        };
        axios
          .get("/rest/mypims/myRentalList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              rental.list = res.data.list;
              rental.total = res.data.total;
            } else {
              console.info(res.data.err_msg);
            }
          });
      },
    });

    onMounted(() => {
      // Mounted
      assets.getList();
      assets.checkAssetsReport();
      rental.getList();
    });

    onUnmounted(() => {
      // UnMounted
    });
    return { assets, rental };
  },
};
</script>

<style lang="scss" scoped>
.sub-wrap .con-wrap .table-col {
  table-layout: auto;
}
</style>
